<template>
    <div class="w-screen p-6">
        <div class="md-layout md-gutter">
           <div class="md-layout bg-white" >    
            <Content textColor="black" labelClass="text-gray-900" label="Name of The Course" class="my-3 text-uppercase w-full"  :text="content.name" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="Course Description" class="my-3 text-uppercase w-full"  :html="content.description" /> 
            <!-- <Content textColor="black" labelClass="text-gray-900"  label="Course Type" class="my-3 text-uppercase w-full"  :text="content.type" />  -->
            <Content textColor="black" labelClass="text-gray-900"  label="Course Link" class="my-3 text-uppercase w-full"  :text="content.online_link" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="Duration" class="my-3 text-uppercase w-full"  :text="content.package_detail && content.package_detail.course_duration?content.package_detail.course_duration+ ' Days' :''" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="Feature Status" class="my-3 text-uppercase w-full"  :text="content.package_detail.is_featured==1?'Featured':'Not Featured'" /> 
           
            <Content textColor="black" labelClass="text-gray-900"  label="Course Properties" class="my-3 text-uppercase w-full" /> 
            <div class="md-layout-item md-size-100">
                <ul>
                    <li v-if="content.package_detail.is_pte_practice" class="font-bold">Number of PTE Practice <span style="margin-left:90px">{{content.package_detail.pte_practice}}</span></li> 
                    <li v-if="content.package_detail.is_practice_test" class="font-bold">Number of Practice Test <span style="margin-left:89px">{{content.package_detail.practice_test}}</span></li> 
                    <li v-if="content.package_detail.is_mock_test" class="font-bold">Number of Mock Test <span style="margin-left:105px">{{content.package_detail.mock_test}}</span></li> 
                    <li v-if="content.package_detail.is_quiz"  class="font-bold">Number of Quiz <span style="margin-left:140px">{{content.package_detail.quiz}}</span></li> 
                    <li v-if="content.package_detail.is_materials" class="font-bold">Materials<span style="margin-left:181px">{{content.package_detail.materials}}</span></li> 
                    <li v-if="content.package_detail.is_live_class" class="font-bold">Live Class<span style="margin-left:179px">{{content.package_detail.live_classes}}</span></li> 
                    <li v-if="content.package_detail.is_webinars" class="font-bold">Webinars <span style="margin-left:178px">{{content.package_detail.webinars}}</span></li> 
                    <li v-if="content.package_detail.is_one_to_one_appointment" class="font-bold">One To One Appointment <span style="margin-left:82px">{{content.package_detail.OneToOneAppointment}}</span></li> 
                </ul>
            </div>
            <div class="md-layout md-size-50">
                
                <div class="md-layout-item md-size-50">
                    <Content textColor="black" labelClass="text-gray-900"  label="New Price" class="my-3 text-uppercase w-full"  :text="'$'+content.package_detail.new_price" /> 
                </div>
                <div class="md-layout-item md-size-50">
                    <Content textColor="black" labelClass="text-gray-900"  label="Old Price" class="my-3 text-uppercase w-full"  :text="'$'+content.package_detail.old_price" /> 
                </div>
                
            </div>
        </div>
    </div>
</div>
</template>

<script>

import Content from "@/components/atom/Content";
import VueClipboard from 'vue-clipboard2';
import Vue from 'vue';

Vue.use(VueClipboard)

export default {
  components: {
    Content,
  },
   props: {
      content: {
          type: Object
      }
  },
  data: function () {
    return {
      active: false,
      component: 'ShowCourseDetails',
      packageInfo: [],
      packages: []
    }
  },
  methods: {
     packageDetail(key) {
      return  this.packageInfo&&this.packageInfo.package_detail&&this.packageInfo.package_detail[key]&&this.packageInfo.package_detail[key]!="undefined"?this.packageInfo.package_detail[key]:0;
    },
  },
  created(){

  }

}
</script>
<style scoped>
</style>